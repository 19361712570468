import React, { useEffect } from "react";
import Home from "./pages/Home";
import AOS from 'aos';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ComingSoon from "./ComingSoon";

const App = () => {
  useEffect(() =>{
    AOS.init({
      offset:100,
      duration:800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <div className='App bg-white dark:bg-gray-900 dark:text-white duration-200'>
        <Router>
          <div>
            <Routes>
              <Route path="/" element={<ComingSoon />} />
            </Routes>
          </div>
        </Router>
    </div>
  );
}

export default App;
