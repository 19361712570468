// import { useState, useEffect } from "react";
// import ComingSoonImage from './assets/ComingSoon.jpg';

// const ComingSoon = () => {
//     const calculateTimeLeft = () => {
//         const targetDate = new Date("2025-06-01T00:00:00");
//         const difference = targetDate - new Date();
//         let timeLeft = {};

//         if (difference > 0) {
//             timeLeft = {
//                 days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//                 hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//                 minutes: Math.floor((difference / (1000 * 60)) % 60),
//                 seconds: Math.floor((difference / 1000) % 60),
//             };
//         }
//         return timeLeft;
//     };

//     const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//     useEffect(() => {
//         const timer = setInterval(() => {
//             setTimeLeft(calculateTimeLeft());
//         }, 1000);
//         return () => clearInterval(timer);
//     }, []);

//     return (
//         <div className="flex flex-col items-center justify-center h-screen text-white text-center p-6 relative overflow-hidden" style={{ backgroundImage: `url(${ComingSoonImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
//             <div className="absolute inset-0 bg-black bg-opacity-50"></div>
//             <h1 className="text-7xl font-extrabold mb-6 drop-shadow-lg animate-pulse relative">Coming Soon</h1>
//             <p className="text-2xl mb-6 font-medium opacity-90 tracking-wide relative">Something amazing is on its way! Stay tuned.</p>
//             <div className="flex space-x-4 text-5xl font-bold mb-8 animate-fade-in relative">
//                 <span className="bg-white/20 px-6 py-3 rounded-lg shadow-lg">{timeLeft.days}d</span>
//                 <span className="bg-white/20 px-6 py-3 rounded-lg shadow-lg">{timeLeft.hours}h</span>
//                 <span className="bg-white/20 px-6 py-3 rounded-lg shadow-lg">{timeLeft.minutes}m</span>
//                 <span className="bg-white/20 px-6 py-3 rounded-lg shadow-lg">{timeLeft.seconds}s</span>
//             </div>
//             <div className="flex flex-col sm:flex-row items-center gap-4 w-full max-w-md animate-fade-in relative">
//                 <input type="email" placeholder="Enter your email" className="p-4 w-full rounded-lg text-black focus:outline-none shadow-lg text-lg" />
//                 <button className="bg-white text-purple-600 px-6 py-4 rounded-lg w-1/2 font-semibold hover:bg-gray-200 shadow-lg text-lg transition-transform transform hover:scale-105">Notify Me</button>
//             </div>
//             <div className="absolute bottom-6 text-sm opacity-80 animate-fade-in">© 2025 <a href="https://black4est.co.in">BLACK4EST</a></div>
//         </div>
//     );
// };

// export default ComingSoon;

import { useState, useEffect } from "react";
import ComingSoonImage from './assets/ComingSoon.jpg';

const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const calculateTimeLeft = () => {
    const targetDate = new Date("2025-06-01T00:00:00");
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleNotify = async () => {
    if (!email) {
      setMessage("Please enter a valid email.");
      return;
    }
    try {
      const response = await fetch("/api/notify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      setMessage(data.message || "Thank you! You will be notified.");
    } catch (error) {
      setMessage("Error sending email. Please try again.");
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center h-screen text-white text-center p-6 relative overflow-hidden"
      style={{ backgroundImage: `url(${ComingSoonImage})`, backgroundSize: "cover", backgroundPosition: "center" }}
    >
      <div className="absolute inset-0" style={{background: "rgba(0, 0, 0, 0.3)"}}></div>
      <h1 className="text-7xl font-extrabold mb-6 drop-shadow-lg animate-pulse relative">Coming Soon</h1>
      <p className="text-2xl mb-6 font-medium opacity-90 tracking-wide relative">Something amazing is on its way! Stay tuned.</p>
      <div className="flex space-x-4 text-5xl font-bold mb-8 animate-fade-in relative">
        <span className="bg-white/20 px-6 py-3 rounded-lg shadow-lg">{timeLeft.days}d</span>
        <span className="bg-white/20 px-6 py-3 rounded-lg shadow-lg">{timeLeft.hours}h</span>
        <span className="bg-white/20 px-6 py-3 rounded-lg shadow-lg">{timeLeft.minutes}m</span>
        <span className="bg-white/20 px-6 py-3 rounded-lg shadow-lg">{timeLeft.seconds}s</span>
      </div>
      <div className="flex flex-col sm:flex-row items-center gap-4 w-full max-w-md animate-fade-in relative">
        <input
          type="email"
          placeholder="Enter your email"
          className="p-4 w-full rounded-lg text-black focus:outline-none shadow-lg text-lg"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="bg-white w-1/2 text-gray-700 px-6 py-4 rounded-lg font-semibold hover:bg-gray-200 shadow-lg text-lg transition-transform transform hover:scale-105"
          onClick={handleNotify}
        >
          Notify Me
        </button>
      </div>
      {message && <p className="mt-4 text-lg text-left text-red-500">{message}</p>}
      <div className="absolute bottom-6 text-sm animate-fade-in">© 2025 Your Company. All rights reserved.</div>
    </div>
  );
};

export default ComingSoon;